import Image from "next/image";

import dynamic from "next/dynamic";
import { Facebook, Instagram, Mail, MapPin, Phone } from "lucide-react";
import { email, phoneNumber } from "@/constants/home/contactData";
import Logo from "./logo";

const FaPinterest = dynamic(
  () => import("react-icons/fa/index").then((mod) => mod.FaPinterest),
  {
    ssr: false,
  }
);

function Footer() {
  const socialFooter = [
    {
      icon: <Facebook size={20} />,
      src: "https://www.facebook.com/people/InviteDekho/61563486989543/",
      ariaLabel: "Facebook",
    },
    {
      icon: <FaPinterest size={20} />,
      src: "https://in.pinterest.com/invitedekho/?actingBusinessId=996491992458563129",
      ariaLabel: "Pinterest",
    },
    {
      icon: <Instagram size={20} />,
      src: "https://www.instagram.com/invite.dekho/",
      ariaLabel: "Instagram",
    },
  ];

  const listInvitation = [
    {
      title: "Wedding Website",
      url: "/designs/wedding-website-invitation/",
    },
    {
      title: "PDF Invitations",
      url: "/designs/wedding-pdf-invitation/",
    },
    {
      title: "Video Invitations",
      url: "/designs/wedding-video-invitation/",
    },
  ];

  const listServices = [
    {
      title: "Gallery Sharing",
      url: "https://www.invitedekho.com/template/bab297fcab1a8fde1158e702b2115aa917bb7e203b9158486bf741d9f77c0b92/gallery/",
    },
    {
      title: "Budget Planning",
      url: "/budget/demo/",
    },
  ];

  const listNav1 = [
    {
      title: "About",
      url: "/about/",
    },
    {
      title: "Feedbacks",
      url: "#feedbacks",
    },
    {
      title: "Blog",
      url: "/blog/",
    },
    {
      title: "Contact",
      url: "/contact/",
    },
  ];

  const listNav2 = [
    {
      title: "Privacy Policy",
      url: "/privacy-policy/",
    },
    {
      title: "Payment Refund Policy",
      url: "/payment-refund-policy/",
    },

    {
      title: "Disclaimer",
      url: "/disclaimer/",
    },
    {
      title: "Terms & Conditions",
      url: "/terms-and-conditions/",
    },
    {
      title: "Shipping & Delivery Policy",
      url: "/shipping-and-delivery-policy/",
    },
  ];

  const contactInfo = [
    {
      id: 1,
      value: (
        <a
          href={`mailto: ${email}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Mail"
        >
          {email}
        </a>
      ),
      icon: <Mail style={{ marginRight: 4 }} />,
    },
    {
      id: 2,
      value: (
        <a
          href={`tel:+91 ${phoneNumber}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Phone"
        >
          +91 {phoneNumber}
        </a>
      ),
      icon: <Phone style={{ marginRight: 4 }} />,
    },
    {
      id: 3,
      value: "Sector 137, Noida - 201305",
      icon: <MapPin style={{ marginRight: 4 }} />,
    },
  ];
// /* SHAADIVINES TECHNOLOGIES PRIVATE LIMITED */

// width: 330px;
// height: 66px;

// font-family: 'Poppins';
// font-style: normal;
// font-weight: 800;
// font-size: 22px;
// line-height: 33px;

// color: #FFFFFF;


// /* Inside auto layout */
// flex: none;
// order: 0;
// align-self: stretch;
// flex-grow: 0;

  return (
    // bg-footer bg-no-repeat bg-center bg-cover
    <footer className=" relative lg:p-0 p-1  bg-lightRed">
      {/* <Image
        width={130}
        height={130}
        src="/assets/home/flower-bottom.svg"
        alt="Logo"
        className="absolute bottom-0 h-[130px] w-auto "
      /> */}
      <div className="grid grid-cols-12 gap-6 lg:px-28 px-4 py-10">
        {/* First Column */}
        <div className="lg:col-span-4 col-span-12">
          <div className=" lg:text-3xl text-white">
            <Logo bgRed /> <br />
            <h2 className="font-extrabold font-poppins lg:font-semibold text-[1.2rem] lg:text-3xl">SHAADIVINES TECHNOLOGIES PRIVATE LIMITED</h2>
          </div>
          <p className="text-white lg:text-base text-sm mt-4">
            Welcome and open yourself to your <br></br>truest love this year
            with us!
          </p>
          <div className="mt-4 flex gap-4">
            {socialFooter.map((item, index) => (
              <a
                href={item.src}
                target="_blank"
                rel="noreferrer"
                aria-label={item.ariaLabel}
                key={index}
                className="bg-white p-2 text-lightGray rounded-md shadow-blr-box cursor-pointer mr-2
                flex items-center justify-center w-12 h-12  hover:bg-lightGray hover:text-white"
              >
                <div>{item.icon}</div>
              </a>
            ))}
          </div>
        </div>
        {/* Second Column */}
        <div className="lg:col-span-8 col-span-12 flex lg:flex-row flex-col lg:gap-20 gap-6 font-poppins">
          <div>
            <h3 className="text-xl font-semibold lg:font-light md:text-4xl text-white font-poppins">
              Invitations
            </h3>
            <ul className="list-none p-0 m-0 text-white font-base pb-4">
              {listInvitation.map((nav, index) => (
                <li key={index} className="p-0 mt-2 cursor-pointer flex items-center gap-x-2">
                  <img src="/assets/home/arrow-right.svg" alt="arrow-right" className="sm:hidden block" />
                  <a
                    className="text-[1rem] no-underline "
                    href={nav.url}
                    aria-label={nav.title}
                  >
                    {nav.title}
                  </a>
                </li>
              ))}
            </ul>
            <h3 className="text-xl font-semibold lg:font-light md:text-4xl text-white font-poppins">
              Links
            </h3>
            <ul className="list-none p-0 m-0 text-white font-base">
              {listNav1.map((nav, index) => (
                <li key={index} className="p-0 mt-2 cursor-pointer  flex items-center gap-x-2">
                  <img src="/assets/home/arrow-right.svg" alt="arrow-right" className="sm:hidden block" />
                  <a
                     className="text-[1rem] no-underline "
                    href={nav.url}
                    aria-label={nav.title}
                  >
                    {nav.title}
                  </a>
                </li>
              ))}
              <li className="p-0 mt-2 cursor-pointer  flex items-center gap-x-2">
                <img src="/assets/home/arrow-right.svg" alt="arrow-right" className="sm:hidden block" />
                <a
                  className="text-inherit no-underline "
                  href="/partner/registration"
                  aria-label="Partner Registration"
                >
                  Partner Registration
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-semibold lg:font-light md:text-4xl text-white font-poppins">
              Support
            </h3>
            <ul className="list-none p-0 m-0 text-white font-base">
              {listNav2.map((nav, index) => (
                <li key={index} className="p-0 mt-2 cursor-pointer  flex items-center gap-x-2">
                  <img src="/assets/home/arrow-right.svg" alt="arrow-right" className="sm:hidden block" />
                  <a
                     className="text-[1rem] no-underline "
                    href={nav.url}
                    aria-label={nav.title}
                  >
                    {nav.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* Third Column */}
          <div className="lg:mb-0 mb-0">
            <h3 className="text-xl font-semibold lg:font-light md:text-4xl text-white font-poppins">
              Contact
            </h3>
            <p className="text-white text-base my-2">
              Would you have any inquiries? Please feel free to contact us.
            </p>
            {contactInfo.map((item) => (
              <span
                key={item.id}
                className="text-white text-base lg:my-6 my-4 flex items-center"
              >
                <span className="bg-white text-lightGray p-2 text-2xl shadow-blr-box rounded-md mr-4">
                  {item.icon}
                </span>
                {item.value}
              </span>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
