import Footer from "@/components/common/footer-sv";
import NewHeaderSV from "@/components/common/new-header-sv";

export default function Custom404() {
  return (
    <section className="w-screen h-screen flex flex-col justify-between">
      <NewHeaderSV pageType="guest"/>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-4xl font-semibold">404 - Page Not Found</h1>
        <p className="text-lg">
          Sorry, the page you are looking for does not exist.
        </p>
        <a href="/" className="text-darkYellow font-semibold">
          Go back to Home
        </a>
      </div>
      <Footer/>
    </section>
  );
}
